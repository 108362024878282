RESET

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%;
  font: inherit; */
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Rubik";
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.ant-select-item-option-content {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #080f6c;
}
.ant-select-item-option-content svg {
  margin-right: 0.3rem;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-input,
.ant-input:focus,
.ant-input:active,
.ant-input {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-input-status-error,
.ant-input-status-error:focus,
.ant-input-status-error:active {
  background: #fff !important;
  border-color: #ff4d4f !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #080f6c;
}
.ant-input-number-input {
  color: #080f6c;
}
textarea {
  color: #080f6c !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #7e00fd !important;
}
.ant-tabs-ink-bar {
  background: #7e00fd !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  ):after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  ):before {
  display: none !important;
}

.ant-upload.ant-upload-select.ant-upload-select-text {
  width: 100% !important;
}

.ant-select-multiple .ant-select-selection-item {
  height: 32px !important;
  margin-top: 4px !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.react-loading-skeleton {
  background-color: #cacaca !important;
}
.react-loading-skeleton:after {
  background-image: linear-gradient(
    90deg,
    #cacaca,
    #dfdfdf,
    #cacaca
  ) !important;
}

.react-pdf__Page__annotations {
  width: auto !important;
  height: auto !important;
}
